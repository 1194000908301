import * as React from "react";
import Carousel from "../components/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";

import PageLayout from "../components/PageLayout";
import ForSale from "../sections/ForSale";
import PastGallery from "../sections/PastGallery";
import Seo from "../components/Seo";

const IndexPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query IndexQuery {
          allMarkdownRemark {
            edges {
              node {
                id
                frontmatter {
                  title
                  sold
                  price
                  slug
                  images {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO]
                        layout: FULL_WIDTH
                        aspectRatio: 1.777
                      )
                    }
                  }
                  featuredImage {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO]
                        aspectRatio: 1.777
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { edges } = data.allMarkdownRemark;
        const bikesForSale = edges.filter(
          (bike) => !bike.node.frontmatter.sold
        );
        const soldBikes = edges.filter((bike) => bike.node.frontmatter.sold);

        return (
          <PageLayout>
            <Seo title="" slug="/" />
            <Carousel autoSpeed={5500}>
              <StaticImage
                src="../images/slide_01.png"
                alt="bike 01"
                placeholder="blurred"
                layout="fullWidth"
                aspectRatio={1.777}
              />
              <StaticImage
                src="../images/slide_02.png"
                alt="bike 02"
                placeholder="blurred"
                layout="fullWidth"
                aspectRatio={1.777}
              />
              <StaticImage
                src="../images/slide_03.png"
                alt="bike 03"
                placeholder="blurred"
                layout="fullWidth"
                aspectRatio={1.777}
              />
              <StaticImage
                src="../images/slide_04.png"
                alt="bike 04"
                placeholder="blurred"
                layout="fullWidth"
                aspectRatio={1.777}
              />
            </Carousel>
            <div id="bikes"></div>
            <ForSale title="For Sale" items={bikesForSale} />
            {soldBikes.length > 0 && <PastGallery bikes={soldBikes} />}
          </PageLayout>
        );
      }}
    />
  );
};

export default IndexPage;
