import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ForSale = ({ items, title }) => {
  if (items.length == 0) {
    return <></>;
  }
  return (
    <section className="for-sale index__section">
      <h3>{title}</h3>
      <div className="index__grid">
        {items.map(({ node }, i) => {
          const image = getImage(node.frontmatter.featuredImage);
          const { title, slug, price } = node.frontmatter;
          return (
            <div className="for-sale__cell" key={`${title}-${i}`}>
              <Link to={`/${slug}`} key={title}>
                <GatsbyImage image={image} alt={title} />
              </Link>
              <p className="for-sale__title">{title}</p>
              <p>
                <strong>${price}</strong>
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default ForSale;
