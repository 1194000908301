import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { SRLWrapper } from "simple-react-lightbox";

const PastGallery = ({ bikes }) => {
  const lightboxOptions = {
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false,
    },
  };
  return (
    <div className="index__section">
      <h3>Gallery</h3>
      <div className="past-gallery__gallery">
        <SRLWrapper options={lightboxOptions}>
          {bikes.map(({ node }) => (
            <>
              <GatsbyImage
                image={
                  node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                }
                alt={`${node.frontmatter.title} featured image`}
                key={node.frontmatter.title}
              />
              {!!node.frontmatter.images &&
                node.frontmatter.images.map(({ childImageSharp }, i) => (
                  <GatsbyImage
                    image={childImageSharp.gatsbyImageData}
                    alt={`${node.frontmatter.title} image ${i + 1}`}
                    key={`${node.frontmatter.title}-image-${i}`}
                  />
                ))}
            </>
          ))}
        </SRLWrapper>
      </div>
    </div>
  );
};

export default PastGallery;
